import React, { useEffect, useRef, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment-timezone'
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../styles/ClickableCalendar.css'
import { useNavigate } from "react-router-dom";
import { EventInfo } from '../../../../models/event/eventModel';

export interface ClickableCalendarProps {
  events: EventInfo[];
}

export const ClickableCalendar:React.FC<ClickableCalendarProps> = (props) =>{
  const { events } = props

  const navigate = useNavigate();

  const handleEventClick =(e:any)=>{
    e?.id && navigate('/events/eventpage/' + e.id)
  }

  // Set calendar timezone here
  moment.tz.setDefault('America/New_York');
  const localizer = momentLocalizer(moment);
  return (
    <div style={{height:'100%', width:'100%'}}>
      <Calendar
        style={{height:'100%', width:'100%'}}
        localizer={localizer}
        onSelectEvent={(e) => handleEventClick(e)}
        events={events}
        startAccessor={(event) => event.startTime}
        endAccessor={(event) => event.endTime}
      />
    </div>
  )
}