import React from 'react';
import CollapsableContent from '../../content/CollapsableContent';

const AboutPage = (props: any) =>
{
	const [showPatron, setShowPatron] = React.useState<boolean>(false);
	const patronButtonText = showPatron ? " — ": " ▼ ";

	const [showVolunteer, setShowVolunteer] = React.useState<boolean>(false);
	const volunteerButtonText = showVolunteer ? " — ": " ▼ ";

	const [showDonor, setShowDonor] = React.useState<boolean>(false);
	const donorButtonText = showDonor ? " — ": " ▼ ";

	return(
		<React.Fragment>
			<div>
				<h2>Our History</h2>
				<p>
					We originally established ourselves as the 
					<span style={{ fontWeight: 575 }}> Metrowest Board Gaming
					(MBG)</span> meetup group, in March 2013, as a way for
					several overlapping gaming groups in the Metrowest area of
					Massachusetts to prevent game nights from conflicting with
					each other.  Our members enjoy gathering together to play
					strategy board games, deck-building games, LCG's, tabletop
					RPG's miniatures games, and the like.
				</p>
				<p>
					As we continued to attract more members located outside of
					Metrowest, it made sense for us to re-establish as the
					<span style={{ fontWeight: 575 }}> Tabletop Gamers Alliance
					(TGA)</span>.
				</p>
				<p>
					Our premiere events initially consisted of hosting all-day
					gaming events once per calendar quarter, called
					Quarterlies.  2018 was the first year that we hosted our
					first gaming convention, 
					<span style={{ fontWeight: 575 }}> Gamacea</span>.  As
					the TGA group's Premiere Event hosting operations and
					finances grew in complexity, we established
					<span style={{ fontWeight: 575 }}> Gamers Alliance LLC </span>
					in 2019.
				</p>
			</div>

			<div>
				<h2>Who We Are</h2>
				<p>
					Randy (AKA das_ninja) is the original founder of
					<span style={{ fontWeight: 575 }}> MBG</span>,
					<span style={{ fontWeight: 575 }}> TGA</span>,
					and <span style={{ fontWeight: 575 }}> Gamers Alliance
					LLC</span> .  He is active in the business and technical
					operations, as well as software development, for
					<span style={{ fontWeight: 575 }}> Gamers Alliance LLC</span> .
				</p>
				<p>
					JJ (AKA daJJbomb) is both an Event Host and software
					developer for us.
				</p>
				<p>
					Derek (AKA Golden Domer) is our Treasurer.
				</p>
			</div>

			<div>
				<h2>The Mission</h2>
				<p style={{ fontStyle: "italic" }}>
					Unite gamers for the purposes of great times and community
					together through the use of modern technology.
				</p>
				<p>
					Many of the sites/apps/tools utilized by gaming hobbyists
					have fallen behind the pace of modern software technology;
					resulting in the mass-adoption of less-than-ideal and
					outdated experiences compared to the non-gaming
					sites/apps/tools that we use today.  Our goal is to bridge
					this technology gap so that gamers can spend less time
					fighting with old technology and spend more time gaming.
				</p>
			</div>

			<div>
				<h2>How You Can Help Us with Our Mission</h2>

				


				<div className="simple-border">
					<div onClick={() => { setShowPatron(!showPatron); }}>
						<span className="head3">Become a Patron</span>
						<span className="float-right">{patronButtonText}</span>
					</div>
				
					<div style={{ display: showPatron ? 'block' : 'none' }}>
						<p><h4>Sponsorship</h4></p>

						<p>
							We have sponsorship opportunities available for our
							Premiere Events:
							<span style={{ fontWeight: 575 }}> Gamacea </span> 
							(typically held in June) and
							<span style={{ fontWeight: 575 }}> Titan Buster </span> 
							(typically held in August).  Submit a&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/11/create/42" target="_blank" rel="noreferrer">
								Sponsorship Enquiry
							</a>
							&nbsp;via our service portal for more details.
						</p>




						<p><h4>Host a +Event</h4></p>

						<p>
							If you host large events where more than 100 people will
							RSVP/attend,&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/11/create/43" target="_blank" rel="noreferrer">
								upgrade your event in our system to a +Event
							</a>
							. 
							<span style={{ fontWeight: 575 }}> +Events </span> 
							have all of the same features as regular events
							and the following features:
							<ul>
								<li>Allow more than 100 RSVP's</li>
								<li>
									More 
									<span style={{ fontWeight: 575 }}> +Event</span>-only
									features coming soon!
								</li>
							</ul>
						</p>




						<p><h4>Host a +Event & Commission Custom Development</h4></p>

						<p>
							If you wish your
							<span style={{ fontWeight: 575 }}> +Event </span>
							could do something that is not currently possible 
							on our site, you can commission us to develop
							additional features for your
							<span style={{ fontWeight: 575 }}> +Event</span>. 
							Custom development will involve additional costs, 
							but we will generate a quote for you before we
							start any work.  Let us know about the features you 
							would like for us to develop for you via a&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/11/create/43" target="_blank" rel="noreferrer">
								+Event upgrade request
							</a>
							&nbsp;on our service portal
						</p>
					</div>
				</div>


				<p></p>


				<div className="simple-border">
					<div onClick={() => { setShowVolunteer(!showVolunteer); }}>
						<span className="head3">Become a Volunteer</span>
						<span className="float-right">{volunteerButtonText}</span>
					</div>

					<div style={{ display: showVolunteer ? 'block' : 'none' }}>
						<p><h4>Event Hosting</h4></p>

						<p>
							Whether you currently host a game night or are interested
							in hosting one, we encourage you to&nbsp;
							<a href="https://tabletopgamersalliance.com/files/Getting_Started_with_Your_TGA_Online_Account.pdf" target="_blank" rel="noreferrer">
								create an account
							</a>
							&nbsp;and start listing your event on our site so that you
							can start attracting new people to your event and begin
							utilizing features such as recurring events and allowing
							guests to schedule game sessions at your events.
						</p>




						<p><h4>Venue Hosting</h4></p>

						<p>
							Are you the owner of a venue that is interested in hosting
							a game night, but have no idea where to start?  List your
							venue on our site to let event hosts know that you are open
							to allowing them to host game nights at your establishment.
							You can use our&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/1/create/12" target="_blank" rel="noreferrer">
								venue request form
							</a>
							&nbsp;to give us the details to add to our system.
						</p>




						<p><h4>FLGS / FLGC</h4></p>

						<p>
							Do you own or operate an FLGS (Friendly Local Game Store)
							or FLGC (Friendly Local Game Cafe)?  We encourage you to
							use our&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/1/create/12" target="_blank" rel="noreferrer">
								venue request form
							</a>
							&nbsp;to let us know to add your venue to our system.  
							Then, we encourage you to both&nbsp;
							<a href="https://tabletopgamersalliance.com/files/Getting_Started_with_Your_TGA_Online_Account.pdf" target="_blank" rel="noreferrer">
								create an account
							</a>
							&nbsp;and then start listing game nights for your venue.
						</p>




						<p><h4>Session Hosting at Premiere Events</h4></p>

						<p>
							We host two Premiere Events (conventions) annually,
							<span style={{ fontWeight: 575 }}> Gamacea</span> and 
							<span style={{ fontWeight: 575 }}> Titan Buster</span>.  
							They both offer ample opportunities for you to host
							a game session of some sort; teach some people how
							to play Caverna, run a six-player game of Twilight
							Imperium, even run a D&D one-shot, or whatever else
							you can dream up!
						</p>

						<p>
							If you're interested, the next step is to register for the
							Premiere Event that you want to attend and then create the
							session(s) that you would like to host on the respective
							Premiere Event page.  Both of our Premiere Events occur
							during the summer, so look for registration to be open for
							them during the first half of the year.
						</p>




						<p><h4>Staffing at Premiere Events</h4></p>

						<p>
							Are you interested in getting involved with Premiere Events
							beyond hosting game sessions?  We are looking to expand the
							staff for our Premiere Events so that we can offer more
							services to our attendees.
						</p>

						<p>
							At this time, we are seeking people to take on the
							following roles for
							<span style={{ fontWeight: 575 }}> Gamacea</span>:
							<ul>
								<li>Social Media Coordinator</li>
								<li>Industry Relations Coordinator</li>
							</ul>
						</p>

						<p>
							We are also seeking people to take on the following roles
							for
							<span style={{ fontWeight: 575 }}> Titan Buster</span>:
							<ul>
								<li>Social Media Coordinator</li>
								<li>Industry Relations Coordinator</li>
							</ul>

							If you are interested in taking on one of the roles above,
							submit an enquiry using the&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/2/create/4" target="_blank" rel="noreferrer">
								"Other questions" form
							</a>
							&nbsp;on our service portal.
						</p>




						<p><h4>Staffing for Gamers Alliance LLC</h4></p>

						<p>
							If you are interested in helping out at the most serious
							level, we are looking for people to take on the following
							roles on a year-round basis:
							<ul>
								<li>Graphic Designer</li>
								<li>Social Media Coordinator</li>
								<li>Industry Relations Coordinator</li>
								<li>Full-Stack Software Engineer</li>
								<li>Mobile Application Developer</li>
								<li>Interns for any of the roles above</li>
							</ul>

							If you are interested in taking on one of the roles above,
							submit an enquiry using the&nbsp;
							<a href="https://gamers-alliance.atlassian.net/servicedesk/customer/portal/1/group/2/create/4" target="_blank" rel="noreferrer">
								"Other questions" form
							</a>
							&nbsp;on our service portal.
						</p>
					</div>
				</div>


				<p></p>


				<div className="simple-border">
					<div onClick={() => { setShowDonor(!showDonor); }}>
						<span className="head3">Become a Donor</span>
						<span className="float-right">{donorButtonText}</span>
					</div>

					<div style={{ display: showDonor ? 'block' : 'none' }}>
						<p>
							When we initially began as a humble Meetup group in 2013,
							we asked our members to donate $1 each year to help cover
							the cost of the Meetup subscription.
						</p>

						<p>
							As the years have gone by; card payments have taken the
							lead over cash, card payment processing fees have
							increased, we formed an LLC, we started developing
							technology which needs to be hosted on a server, and the
							cost of an annual Meetup subscription has more than
							quintupled.
						</p>

						<p>
							<p style={{ fontWeight: "bold" }}>
								Expected 2025 Operating Costs
							</p>
							<table>
								<tbody>
									<tr>
										<td style={{ borderStyle: "none" }}>Domain registrations</td>
										<td style={{ borderStyle: "none" }}>$42</td>
									</tr>
									<tr>
										<td style={{ borderStyle: "none" }}>Web server hosting</td>
										<td style={{ borderStyle: "none" }}>$144</td>
									</tr>
									<tr>
										<td style={{ borderStyle: "none" }}>e-mail hosting</td>
										<td style={{ borderStyle: "none" }}>$12</td>
									</tr>
									<tr>
										<td style={{ borderStyle: "none" }}>Meetup subscription</td>
										<td style={{ borderStyle: "none" }}>$381</td>
									</tr>
									<tr>
										<td style={{ borderStyle: "none" }}>Massachusetts LLC annual filing fee</td>
										<td style={{ borderStyle: "none" }}>$500</td>
									</tr>
								</tbody>
							</table>
						</p>

						<p>
							<p style={{ fontWeight: "bold" }}>
								Expected 2025 Premiere Event Organization Costs
							</p>
							At this time, the primary cost of organizing just one of
							our Premiere Events is the cost to rent the function rooms,
							which is generally several thousand dollars per Premiere
							Event.  Above and beyond that, the Premiere Events require
							other expenses like badge stock and other
							supplies/materials.
						</p>

						<p>
							<p style={{ fontWeight: "bold" }}>
								How to Make a Donation
							</p>
							We would be eternally grateful for any monetary donations
							you make.  All donations will be used to cover the costs
							described above and any excess will be utilized to make
							more progress toward achieving our mission.
						</p>




						<p><h4>Square</h4></p>

						<p>
							Our Square store page facilitates the collection of
							donations funded by either credit or debit cards.&nbsp;
							<a href="https://tabletopga.square.site" target="_blank" rel="noreferrer">
								https://tabletopga.square.site
							</a>
						</p>
					</div>
				</div>
			</div>
			
		</React.Fragment>
	);
};

export default AboutPage;
