import React from 'react';
import './landing.css';

const landingpage = (props: any) =>
(
  <div>
    <img className="landingimage" src="https://www.tabletopgamersalliance.com/i/p/2018/q1/TGA_logo_banner_(nova).png" alt="TGA logo large"/>
    <img className="landingimage" src="https://www.tabletopgamersalliance.com/i/q/q2/2016/adq-2016-06-11_25-w.jpg" alt="Event in progress"/>
  </div>
);

export default landingpage;
