//import './App.css';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import TGAMenu from './components/mainnav/mainnav';
import Library from './components/pages/library/librarypage';
import About from './components/pages/about/AboutPage';
import ProfilePage from './components/pages/profile/profilepage';
import ProfileForm from './components/user/profileform';
import ProfileSetup from './components/user/profilesetup';
import Contact from './components/pages/contact/contactpage';
import Landing from './components/pages/landing/landingpage';
import Static1 from './components/pages/static1/static1';
import Gamacea2022 from './components/pages/gama2022/index';
import Gamacea2019 from './components/pages/gama2019/index';
import Gamacea2018 from './components/pages/gama2018/index';
import Gamacea2023 from './components/pages/gama2023/index';
import Gamacea2024 from './components/pages/gama2024/index';
import Q12019 from './components/pages/2019/Q1/index';
import Q32019 from './components/pages/2019/Q3/index';
import Q42019 from './components/pages/2019/Q4/index';
import Q12018 from './components/pages/2018/Q1/index';
import Q32018 from './components/pages/2018/Q3/index';
import Q42018 from './components/pages/2018/Q4/index';
import PostLogRouter from './components/routers/postlogrouter';
import LoginButton from './components/buttons/loginButton';
import LogoutButton from './components/buttons/logoutButton';
import EventCalendar from './components/pages/calendar';
import VenueViewer from './components/pages/venues/VenueViewer';
import SingleVenueView from './components/pages/venues/SingleVenueView';
import { VenueEditor } from './components/pages/venues';
import { isUserTitanBusterVerified } from './models/user/userAuthorization';
import Index from './components/pages/TitanBuster/recruiting/index';
import Validation from './components/pages/TitanBuster/validation/validation';
import Calendar from  './components/pages/TitanBuster/calendar/calendar';
import EventPage from './components/pages/genericEventPage/EventPage';
import SessionCalendar from './components/pages/genericEventPage/sessionCalendar/SessionCalendar';




const AllianceApp = (navTarget: any) =>
{
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [userIsAdmin, setUserIsAdmin] = useState(false)
    const [userTGAid, setUserTGAID] = useState<number>();
    const [userTGAName, setUserTGAName] = useState("");
    const [userTheme, setUserTheme] = useState("default");
    const [userAccessToken, setUserAccessToken] = useState<string>();
    const [titanBusterView, setTitanBusterView] = useState<boolean>(false);

    const onNavClick = useCallback((event: { currentTarget: any; }) =>
    {
        console.log("You clicked ", event.currentTarget)
    }, []);





    interface IMeta
    {
        tga_id: any
    }





    function getTGAid(dat: IMeta)
    {
        return (dat.tga_id);
    }





    useEffect(() =>
    {
        const getUserData = async (userAuth0Name: string) =>
        {
            if (userAuth0Name !== "") {
                try {
                    const accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                            scope: "read:current_user",
                        },
                    });

                    const userDataResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/byA0/" + userAuth0Name + "", { headers: { Authorization: `Bearer ${accessToken}`, }, });
                    console.log(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/byA0/" + userAuth0Name + "")
                    const { user_name, id } = await userDataResponse.json();
                    
                    const userRolesResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/userRoles/user/" + id + "", { headers: { Authorization: `Bearer ${accessToken}`, }, });
                    const userRolesBody =  await userRolesResponse.json()
                    const userRoles = userRolesBody.data.map((e:{role:string})=>e.role)
                    setUserIsAdmin(userRoles.includes(1))
                    setUserTGAName(user_name);

                    setUserTheme("default");

                    const titanBusterAccess = await isUserTitanBusterVerified(accessToken,id);
                    setTitanBusterView(titanBusterAccess);
                }
                catch (e: any) {
                    console.log("app=>getUserData: " + e.message);
                }
            }
        };

        const getUserMetadata = async () =>
        {

            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                        scope: "read:current_user",
                    },
                });

                setUserAccessToken(accessToken);

                let userDetailsByIdUrl = "";

                if (user != null) {
                    userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;

                    const metadataResponse = await fetch(userDetailsByIdUrl, {
                        headers: { Authorization: `Bearer ${accessToken}`, },
                    });

                    const { user_id, user_metadata } = await metadataResponse.json();

                    var temp_id = getTGAid(user_metadata);

                    setUserTGAID(temp_id); // TODO: need to add more logic to handle this gracefully when metadata does not exist upon first login

                    getUserData(user_id);
                }
            }
            catch (e: any) {
                console.log("app=>getUserMetaData: " + e.message);
            }
        };

        getUserMetadata();
    }, [getAccessTokenSilently, getTGAid, user]);


    const getUserDataSansEffect = async (tga_id: number) =>
    {
        if (tga_id !== 0)
        {
            try
            {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                        scope: "read:current_user",
                    },
                });

                const userDataResponse = await fetch(process.env.REACT_APP_REFLEBULA + "/a1/tga/user/" + tga_id + "", { headers: { Authorization: `Bearer ${accessToken}`, }, });

                const { user_name } = await userDataResponse.json();

                console.log("app=>getUserDataSansEffect=>tga_id: " + user_name);

                setUserTGAName(user_name);
            }
            catch (e: any) {
                console.log("app=>getUserDataSansEffect: " + e.message);
            }
        }
    };


    const getUserStats = async () =>
    {
        try
        {
            const accessToken = await getAccessTokenSilently({
                authorizationParams:
                {
                    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                    scope: "read:current_user",
                },
            });

            let userDetailsByIdUrl = "";

            if (user != null)
            {
                userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;

                const metadataResponse = await fetch(userDetailsByIdUrl,
                {
                    headers: { Authorization: `Bearer ${accessToken}`, },
                });

                const { user_metadata } = await metadataResponse.json();

                setUserTGAID(getTGAid(user_metadata));

                userTGAid && getUserDataSansEffect(userTGAid);
            }
        }
        catch (e: any)
        {
            console.log("app=>getUserStats: " + e.message);
        }
    }

    if(!isAuthenticated && userTGAName === "")
    {
        getUserStats();
    }

    return (
        <React.Fragment>
            <link rel="stylesheet" type="text/css" href={"/themes/" + userTheme + "/main.css"} />
            <div className='master-canvas'>
            <div className="container">
                <div className="TGAMenu" >
                    <TGAMenu callback={(a: any) => onNavClick(a)} showAdminFunctions={userIsAdmin} showTitanBuster={titanBusterView} />

                    <span className="ProfileMenu">
                        {!isAuthenticated && user !== null &&
                            <LoginButton></LoginButton>
                        }
                        {(isAuthenticated && user !== null && userTGAName !== "") &&
                            <span>
                                <a href="/editprofile">{userTGAName}</a> | <LogoutButton></LogoutButton>
                            </span>
                        }

                    </span>

                </div>




                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/editprofile" element={<ProfileForm />} />
                    <Route path="/profilesetup" element={<ProfileSetup />} />
                    <Route path="/library" element={<Library />} />
                    <Route path="/admin/venues" element={<VenueEditor loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path="/venues" element={<VenueViewer loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path="venues/:venueId" element={<SingleVenueView loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path="/calendar" element={<EventCalendar loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path='/2024/gamacea' element={<Gamacea2024 />} />
                    <Route path='/2023/gamacea' element={<Gamacea2023 />} />
                    <Route path='/2022/gamacea' element={<Gamacea2022 />} />
                    <Route path='/2019/gamacea' element={<Gamacea2019 />} />
                    <Route path='/2018/gamacea' element={<Gamacea2018 />} />
                    <Route path='/2013/Q1' element={<Static1 dest="https://boardgamegeek.com/thread/938058/international-tabletop-day-march-30-2013" />} />
                    <Route path='/2013/Q2' element={<Static1 dest="https://boardgamegeek.com/thread/975845/all-day-quarterly-682013" />} />
                    <Route path='/2013/Q3' element={<Static1 dest="https://www.meetup.com/tabletopgamersalliance/events/139481052/" />} />
                    <Route path='/2013/Q4' element={<Static1 dest="https://www.meetup.com/tabletopgamersalliance/events/145242642/" />} />
                    <Route path='/2014/Q1' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/162882112/" />} />
                    <Route path='/2014/Q2' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/177317542/" />} />
                    <Route path='/2014/Q3' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/199416952/" />} />
                    <Route path='/2014/Q4' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/218071262/" />} />
                    <Route path='/2015/Q1' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/219699112/" />} />
                    <Route path='/2015/Q2' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/220784109/" />} />
                    <Route path='/2015/Q3' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/223795058/" />} />
                    <Route path='/2015/Q4' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/226475430/" />} />
                    <Route path='/2016/Q1' element={<Static1 dest="https://www.meetup.com/TabletopGamersAlliance/events/228235278/" />} />
                    <Route path='/2016/Q2' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2016/q2/" />} />
                    <Route path='/2016/Q3' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2016/q3/" />} />
                    <Route path='/2016/Q4' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2016/q4/" />} />
                    <Route path='/2017/Q1' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2017/q1/" />} />
                    <Route path='/2017/Q2' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2017/q2/" />} />
                    <Route path='/2017/Q3' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2017/q3/" />} />
                    <Route path='/2017/Q4' element={<Static1 dest="https://www.tabletopgamersalliance.com/archive/events/2017/q4/" />} />
                    <Route path='/2018/Q1' element={<Q12018 />} />
                    <Route path='/2018/Q3' element={<Q32018 />} />
                    <Route path='/2018/Q4' element={<Q42018 />} />
                    <Route path='/2019/Q1' element={<Q12019 />} />
                    <Route path='/2019/Q3' element={<Q32019 />} />
                    <Route path='/2019/Q4' element={<Q42019 />} />
                    <Route path="/postlog" element={<PostLogRouter />} />
                    <Route path="/TitanBuster/recruiting" element={<Index loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path="/TitanBuster/validation" element={<Validation loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path="/TitanBuster/temp_schedule" element={<Calendar eventName="Titan Buster" creatorRole="Titan_Buster_Registant"/>} />
                    <Route path="/events/eventpage/:eventId" element={<EventPage loggedInUser={userTGAid} authToken={userAccessToken} />} />
                    <Route path="/events/eventpage/calendar/:eventId" element={<SessionCalendar loggedInUser={userTGAid} authToken={userAccessToken} />} />
                </Routes>
            </div>
            {process.env.NODE_ENV !== "production" &&
                <div className="dev-stat">
                    <p>reflebula server: {process.env.REACT_APP_REFLEBULA}</p>
                    <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
                </div>
            }
            </div>
        </React.Fragment>
    );
}

export default AllianceApp;