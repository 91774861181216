import CollapsableContent from './CollapsableContent';
import React from 'react';
import { EventSeriesProps, EventCreationDetails } from '../pages/calendar/types/eventModel';
import { EventInfo } from '../../models/event/eventModel';
import NewEventForm from '../pages/calendar/components/NewEventForm';
import {localToGMT, getDuration} from '../pages/calendar/utils'
import moment from 'moment';
import { fetchSeriesById } from '../../utils';
import { useNavigate } from "react-router-dom";

interface CollapsableAdminProps {
    title: string,
    startingIsCollapsed: boolean,
    loggedInUser: number,
    authToken: string,
    event: EventInfo,
}

const CollapsableAdmin = (props: CollapsableAdminProps) => {
    const navigate = useNavigate();

    const timeZone = moment.tz.guess();

    const [adminDialog, setAdminDialog] = React.useState<boolean>(false);
    const [series, setSeries] = React.useState<EventSeriesProps>()
    

    const enableAdminDialog = () => {
        setAdminDialog(true);
    }

    React.useEffect(()=> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
        }

        console.log(props.event);

        if (!props.event.seriesId) {
            return
        }

        fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event2/series?id=${props.event.seriesId}`, requestOptions)
            .then(res => res.json())
            .then((res) => {
                if (res.result && res.result === `success`) {
                    console.log(res.data);
                    const ser = {
                        ...res.data,
                        eventsStart: res.data.events_start,
                        eventsEnd: res.data.events_end,
                        eventStartTime: res.data.event_start_time,
                        eventEndTime: res.data.event_end_time,
                        highestTableNumber: res.data.highest_table_number,
                        lowestTableNumber: res.data.lowest_table_number,
                        recurrencePattern: res.data.recurrence_pattern,
                        recurrenceSeperation: res.data.recurrence_seperation

                    }
                    setSeries(ser);
                    return;
        
                } else {
                    console.error(`Backend Error in SetEvent`);
                    console.error(res)
                    res.reason && console.error(`Reason: ${res.reason}`);
                    return;
                }
            })
            .catch((reason) => {
                console.error('Fetch Exception in SetEvent')
                console.error(`Reason: ${reason}`)
                return;
            })
    },[props.event.seriesId])



    const handleEventFormSubmit = (requestBody:EventCreationDetails | EventSeriesProps | EventInfo)=>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        }

        console.log("[handleEventFormSubmit] requestBody");
        console.log(requestBody);
    
        if(Object.keys(requestBody).includes("startTime") && Object.keys(requestBody).includes("id")){
            const eInfo = requestBody as EventInfo
            console.log("[handleEventFormSubmit] handling as existing event");

            //Convert from local to GMT
            const convertedStart = localToGMT(eInfo.startTime,eInfo.startTime,`America/New_York`);
            const convertedEnd = localToGMT(eInfo.endTime,eInfo.endTime,`America/New_York`);
            const newReqBody = {
                start_time: `${convertedStart.date} ${convertedStart.time}`,
                end_time: `${convertedEnd.date} ${convertedEnd.time}`,
                name: eInfo.name,
                info: eInfo.info,
                venue: eInfo.venue,
                website_url: eInfo.websiteUrl,
                slots: eInfo.slots, 
                lowest_table_number: eInfo.lowestTableNumber,
                highest_table_number: eInfo.highestTableNumber,
            };
            const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};
            console.log(newRequestOptions)
            // Editing existing event instance
            return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event2/single?id=${eInfo.id}`, newRequestOptions)
            .then(res => res.json())
            .then((res) => {
                if (res.result && res.result === `success`) {
                    setAdminDialog(false);
                    return true;
        
                } else {
                    console.error(`Backend Error in SetEvent`);
                    console.error(res)
                    res.reason && console.error(`Reason: ${res.reason}`);
                    return false;
                }
            })
            .catch((reason) => {
                console.error('Fetch Exception in SetEvent')
                console.error(`Reason: ${reason}`)
                return false;
            })
        }
    
        if(Object.keys(requestBody).includes("eventsStart") && Object.keys(requestBody).includes("id")){
            console.log("[handleEventFormSubmit] handling as existing event series");
            const eSeries = requestBody as EventSeriesProps
            // Editing existing event series

            const startDate = moment(eSeries.eventsStart).format('YYYY-MM-DD');
            const endDate = moment(eSeries.eventsEnd).format('YYYY-MM-DD');
            const startTime = eSeries.eventStartTime + ':00'
            const endTime = eSeries.eventEndTime + ':00'

            console.log(startDate, endDate, startTime, endTime);
            

            //Convert from local to GMT
            const convertedStart = localToGMT(startDate,startTime,`America/New_York`);
            const convertedEndTime=  localToGMT(startDate,endTime,`America/New_York`);
            const convertedEnd = localToGMT(endDate,endTime,`America/New_York`);

            console.log(convertedStart);
            console.log(convertedEnd);

            const duration = getDuration(eSeries.eventStartTime, eSeries.eventEndTime);
            console.log("[handleEventFormSubmit] duration ", duration);

            const newReqBody = {...eSeries, 
                eventsStart: moment(convertedStart.date).format('MM-DD-YYYY'), 
                eventStartTime: convertedStart.time, 
                eventsEnd: moment(convertedEnd.date).format('MM-DD-YYYY'), 
                eventEndTime: convertedEndTime.time,
                durationMinutes: String(duration),
                timezone: timeZone
            };
            const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};
            console.log("[handleEventFormSubmit] newRequestOptions", newRequestOptions);
            return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event2/series?id=${eSeries.id}`, newRequestOptions)
                .then(res => res.json())
                .then((res) => {
                    if (res.result && res.result === `success`) {
                        setAdminDialog(false);
                        return true;
                    } else {
                    console.error(`Backend Error in SetEventSeries`);
                    res.reason && console.error(`Reason: ${res.reason}`);
                    return false;
                    }
                })
                .catch((reason) => {
                    console.error('Fetch Exception in SetEventSeries')
                    console.error(`Reason: ${reason}`)
                    return false;
                })
        }
    }

    const handleEventDelete = (requestBody: EventInfo | EventSeriesProps) => {
        if (Object.keys(requestBody).includes("startTime")) {
            //delete single instance
            const eInfo = requestBody as EventInfo
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
            }
    
            return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event2/single?id=${eInfo.id}`, requestOptions)
                .then(res => res.json())
                .then((res) => {
                    if (res.result && res.result === `success`) {
                        setAdminDialog(false)
                        navigate('/calendar');
                        return true;
                    } else {
                        console.error(`Backend Error in handleEventDelete`);
                        res.reason && console.error(`Reason: ${res.reason}`);
                        return false;
                    }
                })
                .catch((reason) => {
                    console.error('Fetch Exception in handleEventDelete')
                    console.error(`Reason: ${reason}`)
                    return false;
                })
        } else {
          // delete event series
            const eSeries = requestBody as EventSeriesProps
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody)
            }
    
            return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event2/series?id=${eSeries.id}`, requestOptions)
                .then(res => res.json())
                .then((res) => {
                    if (res.result && res.result === `success`) {
                        setAdminDialog(false)
                        navigate('/calendar');
                        return true;
                    } else {
                        console.error(`Backend Error in handleEventDelete`);
                        res.reason && console.error(`Reason: ${res.reason}`);
                        return false;
                    }
                })
                .catch((reason) => {
                    console.error('Fetch Exception in handleEventDelete')
                    console.error(`Reason: ${reason}`)
                    return false;
                })
        }
    }

    const fakeRsvpToggle = (eid: number) => {
        //Hackiness for now
        return Promise.resolve(false);
    }

    return(
        <div>
            <CollapsableContent title={props.title} startingIsCollapsed={props.startingIsCollapsed}>
                <button onClick={() => enableAdminDialog()}>To Event Host Panel</button>
            </CollapsableContent>
            <dialog style={{zIndex:20, height:'75%', position:'absolute', top:100, width:'60%'}} open={adminDialog}>
                <NewEventForm 
                    onCancel={()=>setAdminDialog(false)}
                    event={props.event}
                    series={series}
                    loggedInUser={props.loggedInUser} 
                    authToken={props.authToken}
                    onRSVPToggle={fakeRsvpToggle}
                    onSubmitEvent={handleEventFormSubmit}
                    onSubmitSeries={handleEventFormSubmit}
                    onDelete={handleEventDelete}
                    readonly={false}
                    disableRsvp={true}
                    noReset={true}
                />
            </dialog>
        </div>
    )
}

export default CollapsableAdmin;