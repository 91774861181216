import React from 'react';
import FeatureInfo from '../../../content/FeatureInfo'
import { getRSVPsToEvent, eventRsvpList } from '../../calendar/types/eventModel';
import {getSpecificEvent, EventInfo } from "../../../../models/event/eventModel"
import { getUserDetails, UserDetails } from "../../../../models/user/userInfo"
import { getVenueDetails, VenueDetails } from '../../venues/venueModels';
import { useNavigate, useParams } from 'react-router-dom';
import Calendar from  '../../../../components/pages/TitanBuster/calendar/calendar';
import GenericSessionCalendar, { CalSession } from './GenericSessionCalendar';
import { ToggleRSVPtoEvent } from '../../../../models/event/userEventModel';
import { toggleUserSessionStatus } from '../../../../functions/Sessions';
import { getGsessionsForEvent } from '../../../../functions/Sessions';
import { EventSessionCreationPermissionStatus, getUserSessionCreationPermissionsForEvent } from '../../../../functions/Events';

//TODO: Fix this big calendar Hackiness
export type Value = any;

interface sessionCalendarProps
{
  loggedInUser ?: number,
  authToken ?: string
}

const SessionCalendarPage = (props: sessionCalendarProps) =>
{

  const [event, setEvent] = React.useState<EventInfo | undefined>(undefined);
  const [owner, setOwner] = React.useState<string| undefined>(undefined);
  const [user, setUser] = React.useState<UserDetails | undefined>(undefined);
  const [venue, setVenue] = React.useState<VenueDetails | undefined>(undefined);
  const [registerList, setRegisterList] = React.useState<eventRsvpList | undefined>(undefined);
  const [seshList, setSeshList] = React.useState<CalSession[] | undefined>(undefined);
  const [userStatus, setUserStatus] = React.useState<EventSessionCreationPermissionStatus>(EventSessionCreationPermissionStatus.notAUser);
  const [refreshSessions, setRefreshSessions] = React.useState<Boolean>(false);

  const navigate = useNavigate();

  const params = useParams();

  const eventId: number | undefined = params.eventId ? parseInt(params.eventId) : undefined;

  const signUpRequest = (sesh: CalSession): Promise<boolean> => 
  {
    return user ? 

      toggleUserSessionStatus({user: user.id, authToken: props.authToken}, sesh.id, true).then((res) => 
      {
        res && setRefreshSessions(true);
        return res;
      })

    : Promise.resolve(false);
  }


  const relinquishRequest = (sesh: CalSession): Promise<boolean> => 
  {
    return user ? 
  
      toggleUserSessionStatus({user: user.id, authToken: props.authToken}, sesh.id, false).then((res) => 
      {
        res && setRefreshSessions(true);
        return res;
      })
  
    : Promise.resolve(false);
  }

  React.useEffect(() => 
  {
    if(eventId === undefined) {
      setEvent(undefined);
    }
    
    eventId && getSpecificEvent(eventId).then((res) =>
    {
      res && setEvent(res);
      res && setOwner(res.owner);

      res && getVenueDetails(res.venue).then((ven) =>
        {
          setVenue(ven);
        }
      );

    });

  },[eventId]);


  React.useEffect(() =>
  {
    //TODO: Make this trigger a single refresh in a less hacky way.
    if(refreshSessions)
    {
      setRefreshSessions(false);
      return;
    }

    eventId && getGsessionsForEvent(eventId).then((res) => {

      setSeshList(res);
    });
  }, [eventId, refreshSessions]);


  React.useEffect(() => 
  {
    if(props.loggedInUser === undefined) 
    {
      setUser(undefined);
    }
    else 
    {
      getUserDetails(props.loggedInUser).then((res) => 
      {
        if (!res) 
        {
          return;
        }

        res && setUser(res);

      });
    }
  },[props.loggedInUser]);


  React.useEffect(() => 
  {
    if(eventId === undefined) 
    {
      setRegisterList(undefined);
    } 
    else 
    {
      getRSVPsToEvent(eventId).then((rsvp) => 
      {
        rsvp && setRegisterList(rsvp);
      });
    }
  },[eventId]);


  React.useEffect(() => 
  {
    user && eventId && getUserSessionCreationPermissionsForEvent(user.id, eventId).then((result) => 
    {
      setUserStatus(result);
    });

  }, [user]);


  const onEventRSVP = async () => 
  {
    const authUser = {user: props.loggedInUser, authToken: props.authToken};

    if(event?.id && authUser.user && authUser.authToken && await ToggleRSVPtoEvent(event.id, authUser)) 
    {
      if(userStatus === EventSessionCreationPermissionStatus.notAttendingEvent) 
      {
        //TODO: Check if user has editing permissions now (for now, always)
        setUserStatus(EventSessionCreationPermissionStatus.canCreateSessions);
      }

      if(userStatus === EventSessionCreationPermissionStatus.canCreateSessions || userStatus === EventSessionCreationPermissionStatus.noSessionPermissions) 
      {
        setUserStatus(EventSessionCreationPermissionStatus.notAttendingEvent);
      }

      return true;
    }
    else 
    {
      return false;
    }
  }


  if (!event || !owner || !venue || !registerList || !seshList)
  {
    return (<div><b>Loading Event...</b></div>);
  }


  if (event.name === "Titan Buster") 
  {
      return(
        <div>
          <Calendar eventName="Titan Buster" creatorRole="Titan_Buster_Registant"/>
        </div>
      );
  } 
  else if (event.name === "Gamacea 2024") 
  {

    navigate("/2024/gamacea");
    return(<div><b>Loading Event...</b></div>);

  } 
  else if (event.name === "Gamacea 2023") 
  {

    navigate("/2023/gamacea");
    return(<div><b>Loading Event...</b></div>);

  } 
  else
  {
    return(
    <div>
        <FeatureInfo startTime={event.startTime} endTime={event.endTime} name={event.name} owner={owner}/>
        <button className="buttonPrimary" onClick={() => navigate(-1)}>Return to Event Page</button>
        <p></p>
        <GenericSessionCalendar seshList={seshList} onSignUp={signUpRequest} userStatus={userStatus} userId={user?.id} userName={user?.user_name} token={props.authToken} event={event} onEventRSVP={onEventRSVP} onRelinquish={relinquishRequest}/>
        <p></p>
        <button className="buttonPrimary" onClick={() => navigate(-1)}>Return to Event Page</button>
        <p></p>
        <hr></hr>
        {event.mainMapImage && <img src={"https://tabletopgamersalliance.com/i/ev/" +  event.id + "/" + event.mainMapImage} />}
    </div>
    
    );
  }
    

}

export default SessionCalendarPage;