import React from 'react'
import { NavigateAction, momentLocalizer } from 'react-big-calendar'
// @ts-ignore
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import moment from 'moment'

const localizer = momentLocalizer(moment)

interface SessionScheduleViewProps {
    title: string,
    min: Date,
    max: Date
}

export class SessionScheduleView extends React.Component<SessionScheduleViewProps> {

    readonly title_string = this.props.title;
    
    static title = (date: Date) => {
        return `Filler Title`
    }

    static navigate = (date: Date, action: NavigateAction) => {
        switch (action) {
            default:
                return date
        }
    }

    static isSameOrBefore = (first: Date, second: Date): boolean => {
        return first.getFullYear() < second.getFullYear() || (first.getFullYear() === second.getFullYear() && first.getMonth() < second.getMonth()) || (first.getFullYear() === second.getFullYear() && first.getMonth()=== second.getMonth() && first.getDate() <=  second.getDate())
    }

    static range = (startDate: Date, endDate: Date) => {
        let start = startDate
        let end = endDate

        let current = start
        let range = []

        while (SessionScheduleView.isSameOrBefore(current, end)) {
            range.push(new Date(current))
            current.setDate(current.getDate() +1)
        }

        return range
    }

    readonly messages = {
        date: 'Date',
        time: 'Time',
        event: 'Session',
        allDay: 'All Day',
        week: 'Week',
        work_week: 'Work Week',
        day: 'Day',
        month: 'Month',
        previous: 'Back',
        next: 'Next',
        yesterday: 'Yesterday',
        tomorrow: 'Tomorrow',
        today: 'Today',
        agenda: 'Agenda',
      
        noEventsInRange: 'There are no sessions currently for this Event.',
      
        showMore: (total: number) => `+${total} more Sessions`,
      }
    readonly isSameDay = this.props.min.getDay() === this.props.max.getDay()

    readonly max = this.isSameDay ? this.props.max : localizer.endOf(new Date(), 'day');
    readonly min = this.isSameDay ? this.props.min : localizer.startOf(new Date(), 'day');

    render() {
        let { min, max } = this.props
        let range = SessionScheduleView.range(min, max)

        return <TimeGrid {...this.props} min={this.min} max={this.max} range={range} eventOffset={15} step={15} timeslots={4} messages={this.messages} selectable={'ignoreEvents'}/>
    }
}