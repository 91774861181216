import { UserDetails } from '../../../../models/user/userInfo';
import { getDay, getWeekOfMonth, isBefore, setHours, setMinutes } from 'date-fns'
export interface DB_Error {
    result: string,
    reason: string,
    exception?: string,
    message?: string
}

export type ValuePiece = Date | null;

export type Value = any;//ValuePiece | [ValuePiece, ValuePiece]

export interface EventCreationDetails {
    eventsStart:string,
    eventsEnd:string,
    eventStartTime: string,
    eventEndTime: string,
    recurrencePattern:string,
    recurrenceSeperation:number,
    name: string,
    info: string,
    venue: number,
    slots: number,
    websiteUrl: string,
    highestTableNumber: number | null,
    lowestTableNumber: number | null,
    rsvp?: boolean,
}

export interface EventCreationDetailsInstance {
    startTime: string,
    endTime: string,
    name: string,
    info: string,
    venue: number,
    slots: number,
    websiteUrl: string,
    highestTableNumber: number | null,
    lowestTableNumber: number | null,
    rsvp?: boolean,
    series?: number,

}

export enum EventSeriesPreset {
    daily,
    weekly,
    weekdays,
    monthly,
    custom
}
export interface EventSeriesProps {
    id:number,
    info: string,
    name: string,
    owner: number,
    slots: number,
    eventsStart:Date,
    eventsEnd:Date,
    eventStartTime:Date,
    eventEndTime:Date,
    recurrencePattern:string,
    recurrenceSeperation:number,
    venue: number,
    websiteUrl?: string,
    lowestTableNumber: number | null,
    highestTableNumber: number | null,
}

export interface EventInfo {
    endTime: Date,
    highestTableNumber: number | null,
    id: number,
    info: string,
    lowestTableNumber: number | null,
    name: string,
    owner: number,
    slots: number,
    series?: number,
    startTime: Date,
    venue: number,
    websiteUrl?: string
}

export interface eventRsvpList {
    count: number,
    data: UserDetails[]
}

interface GETRsvpList extends eventRsvpList {
    result: string
}

function isRsvpList(resp: GETRsvpList | DB_Error): resp is GETRsvpList {
    return (resp as GETRsvpList).result === 'success';
}


export const getSpecificEvent = (eventId: number): Promise<EventInfo | undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' }
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/events/' + eventId, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getSpecificEvent')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then(res => res.data)
        .catch((reason) => {
            console.log('Fetch Exception in getSpecificEvent')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const getRSVPsToEvent = (eventId: number): Promise<eventRsvpList | undefined> => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Basic YnV6ejpmcjBudGllcg==' },
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/eventUsers/event/' + eventId, requestOptions)
        .then((response) => {
            if (!response.ok) {
                console.log('Fetch Error in getRSVPsToEvent')
                console.log(`HTTP Response Code: ${response.status}`)
                throw ('Bad Fetch');
            } else {
                return response;
            }
        })
        .then(res => res.json())
        .then((res: DB_Error | GETRsvpList) => {
            if (isRsvpList(res)) {
                return res as eventRsvpList;
            } else {
                console.log(`Backend Error in getRSVPsToEvent Reason ${res.reason}`)
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return undefined;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in getRSVPsToEvent')
            console.log(`Reason: ${reason}`)
            return undefined;
        })
}

export const createEvent = (input: EventCreationDetails, authToken: string): Promise<boolean> => {
    const requestBody = {
        vid: input.venue,
        rsvpl: input.rsvp,
        ...input
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/create', requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.status && res.status === `success`) {
                return true;
            } else {
                console.log(`Backend Error in CreateEvent`);
                res.reason && console.log(`Reason: ${res.reason}`);
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in CreateEvent')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

export const updateEvent = (input: EventInfo, authToken: string): Promise<boolean> => {
    const requestBody = {
        uid: input.owner,
        vid: input.venue,
        ...input,
        id: undefined,
        owner: undefined,
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify(input)
    }
    return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/' + input.id + (input.owner ? '?uid=' + input.owner : ''), requestOptions)
        .then(res => res.json())
        .then((res) => {
            if (res.result && res.result === `success`) {
                return true;
            } else {
                console.log(`Backend Error in UpdateVenue`);
                res.reason && console.log(`Reason: ${res.reason}`);
                res.exception && console.log(`Exception ${res.exception}`)
                res.message && console.log(`Message ${res.message}`)
                return false;
            }
        })
        .catch((reason) => {
            console.log('Fetch Exception in UpdateVenue')
            console.log(`Reason: ${reason}`)
            return false;
        })
}

