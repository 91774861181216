import React, { useEffect, useState, SyntheticEvent } from 'react'
import { useWindowDimensions } from '../../../utils'
import { fetchEvents, localToGMT, GMTToLocalIso } from './utils'
import './styles/index.css'; // these styles probably need to move elsewhere
import { EventCreationDetails, EventSeriesProps } from './types/eventModel'
import { EventInfo } from '../../../models/event/eventModel'
import { ClickableCalendar } from './components/ClickableCalendar'
import { EventForm } from './components/EventForm'
import moment from 'moment'

interface EventCalendarProps {
  loggedInUser?: number,
  authToken?: string
}

export const EventCalendar:React.FC<EventCalendarProps> = (props)=>{
  const { loggedInUser, authToken } = props
  const { windowHeight, windowWidth } = useWindowDimensions()
  const [calendarEvents, setCalendarEvents] = useState<EventInfo[]>([])
  const [createEventDialogOpen, setCreateEventDialogOpen] = useState(false)
  const [eventsLoading, setEventsLoading] = useState(false)
  const [eventsLoadingFailed, setEventsLoadingFailed] = useState(false)
  const [userCanCreate, setUserCanCreate] = useState(false);

  //TODO: Make this local time
  const timeZone = 'America/New_York';
  moment.tz.setDefault('America/New_York');

  useEffect(()=>{
    if(calendarEvents.length === 0){
      fetchEvents(setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
    }
  },[authToken])
  
  const handleEventsLoaded = (events: EventInfo[]) => setCalendarEvents(events.map(e=>{
    //Convert Time Zone from GMT to Local
    const startTime = GMTToLocalIso(e.startTime, `America/New_York`);
    const endTime = GMTToLocalIso(e.endTime, `America/New_York`);
    return {start:startTime.dateTime, end:endTime.dateTime, title:e.name, ...e }
  }))

  const handleRSVPToggle =(eid:number)=> {
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${props.authToken}` }
    }
    
    return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${eid}/rsvp`, requestOptions)
    .then(res => res.json())
    .then((res) => {
      if (res.result && res.result === `success`) {
          setCreateEventDialogOpen(false)
          fetchEvents(setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
          return true;
      } else {
          console.error(`Backend Error in handleRSVPToggle`);
          res.reason && console.error(`Reason: ${res.reason}`);
          res.exception && console.error(`Exception: ${res.exception}`);
          return false;
      }
    })
    .catch((reason) => {
        console.error('Fetch Exception in handleRSVPToggle')
        console.error(`Reason: ${reason}`)
        return false;
    })
  }

  const handleEventFormSubmit = (requestBody:EventCreationDetails | EventSeriesProps | EventInfo)=>{
    // TODO: Timezone Conversion of request Body
    // EventCreation Details has 4 strings
    // Event Series Props has 4 Dates
    // Event info has 2 separate
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody)
    }

    //Convert from local to GMT
    const eDetails = requestBody as EventCreationDetails
    const convertedStart = localToGMT(eDetails.eventsStart,eDetails.eventStartTime,`America/New_York`);
    const convertedEnd = localToGMT(eDetails.eventsEnd,eDetails.eventEndTime,`America/New_York`);
    console.log("[handleEventFormSubmit]");
    console.log(eDetails);
    console.log(convertedStart, convertedEnd);

    if (eDetails.recurrencePattern === "ONCE") {
      const newReqBody = {
        start_time: `${convertedStart.date} ${convertedStart.time}`,
        end_time: `${convertedEnd.date} ${convertedEnd.time}`,
        name: eDetails.name,
        info: eDetails.info,
        venue: eDetails.venue,
        website_url: eDetails.websiteUrl,
        slots: eDetails.slots, 
        lowest_table_number: eDetails.lowestTableNumber,
        highest_table_number: eDetails.highestTableNumber,
      };
      const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};
      console.log("newRequestOptions single event");
      console.log(newRequestOptions);

      return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event2/create', newRequestOptions)
        .then(res => res.json())
        .then((res) => {
          if (res.result && res.result === `success`) {
            setCreateEventDialogOpen(false)
            fetchEvents(setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
            return true;
          } else {
            console.error(`Backend Error in CreateEvent`);
            res.reason && console.error(`Reason: ${res.reason}`);
            return false;
          }
        })
        .catch((reason) => {
          console.error('Fetch Exception in CreateEvent')
        console.error(`Reason: ${reason}`)
            return false;
        })
    } else {
      // new event series
      const newReqBody = {
        timezone: 'GMT',
        eventStartTime: `${convertedStart.time}`,
        eventEndTime: `${convertedEnd.time}`,
        eventsStart: `${convertedStart.date}`,
        eventsEnd: `${convertedEnd.date}`,
        name: eDetails.name,
        info: eDetails.info,
        venue: eDetails.venue,
        website_url: eDetails.websiteUrl,
        slots: eDetails.slots, 
        lowestTableNumber: eDetails.lowestTableNumber,
        highestTableNumber: eDetails.highestTableNumber,
        recurrencePattern: eDetails.recurrencePattern,
        recurrenceSeperation: eDetails.recurrenceSeperation,
      };
      const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};
      console.log("newRequestOptions event series");
      console.log(newRequestOptions);

      return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event2/series/create', newRequestOptions)
        .then(res => res.json())
        .then((res) => {
          if (res.result && res.result === `success`) {
            setCreateEventDialogOpen(false)
            fetchEvents(setEventsLoading, handleEventsLoaded, (err)=>console.error(err))
            return true;
          } else {
            console.error(`Backend Error in createEventSeries`);
            res.reason && console.error(`Reason: ${res.reason}`);
            return false;
          }
        })
        .catch((reason) => {
          console.error('Fetch Exception in createEventSeries')
        console.error(`Reason: ${reason}`)
            return false;
        })
    }
  }

  React.useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${props.authToken}` }
    }

    if(props.loggedInUser && props.authToken) {

      fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/canCreate`, requestOptions)
        .then((res) => res.json())
        .then((res) => {
          if(res.result && res.result === `success`) {
              setUserCanCreate(res.value);
          } else {
            console.error(`Backend Error in canCreate`);
            res.reason && console.error(`Reason: ${res.reason}`);
            setUserCanCreate(false);
          }
        })
    } else {
      setUserCanCreate(false);
    }
  },[props.loggedInUser, props.authToken])

  return (
    <div style={{height:windowHeight-375, display:'flex', flexDirection:'column', alignItems:'center'}}>
      <ClickableCalendar
        events={calendarEvents}
      />
      {userCanCreate &&<div 
          id='create-event-button' 
          onClick={()=>setCreateEventDialogOpen(true)}
      >
          Create New Event
      </div>}
      <dialog style={{zIndex:20, height:'75%', position:'absolute', top:100, width:'60%'}} open={createEventDialogOpen}>
        <EventForm 
          onCancel={()=>setCreateEventDialogOpen(false)}
          loggedInUser={props.loggedInUser} 
          authToken={props.authToken} 
          onSubmit={handleEventFormSubmit}
          onRSVPToggle={handleRSVPToggle}
          readonly={false}
        />
      </dialog>
    </div>
  )
}

export default EventCalendar
